<template>
  <BaseAppLayout>
    <router-view></router-view>
  </BaseAppLayout>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import { mapActions, mapGetters } from "vuex";
// import { getAdvisor } from "@/graphql/queries";

export default {
  name: "App",

  data: () => ({}),
  computed: {
    ...mapGetters(["currentUser"]),
  },
  beforeCreate() {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      // console.log("IN HUB", data);

      if (payload.event === "signIn") {
        this.handleSetUser(payload.data, "signIn");
      }
      if (payload.event === "signOut") {
        this.setCurrentUser(null);
        this.$router.push({ name: "SignIn" });
      }
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log("currentAuthenticatedUser in APP", user);
        this.handleSetUser(user);
      })
      .catch(() => this.setCurrentUser(null));
  },
  methods: {
    ...mapActions(["setCurrentUser"]),
    async handleSetUser(user, event) {
      // console.log("Event", event);
      // Current User Group
      let groupLevel = null;

      if (
        user.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ]?.includes("SuperAdmin")
      ) {
        groupLevel = "isSuperAdmin";
      } else if (
        user.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ]?.includes("Advisor")
      ) {
        groupLevel = "isAdvisor";
      }

      const advisorId = user.attributes["custom:advisor_id"]
        ? user.attributes["custom:advisor_id"]
        : null;

      // const jwt = user.signInUserSession?.accessToken?.jwtToken;

      // id from Cognito custom attribute

      if (groupLevel === "isAdvisor") {
        fetch(process.env.VUE_APP_SCORE_API + "advisor/" + advisorId, {
          method: "GET",
          // headers: {
          //   // Authorization: `Bearer ${jwt}`,
          //   "Content-Type": "application/json",
          // },
          // body: JSON.stringify(this.formData),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(
                "Something went wrong locating your account",
                response
              );
            }
          })
          .then((data) => {
            if (data.success) {
              if (
                data.advisor.stripe?.subscription?.subscription_status !==
                  "active" &&
                data.advisor.stripe?.subscription?.subscription_status !==
                  "trialing"
              ) {
                Auth.signOut()
                  .then(() => {
                    this.setCurrentUser(null);
                    this.$router.push({ name: "PublicInactiveAccount" });
                  })
                  .catch((error) => {
                    console.log("ERROR SIGNING OUT", error);
                  });
              } else {
                this.setCurrentUser(data.advisor);

                if (event === "signIn") {
                  this.$router.push({ name: "UserDashboard" });
                }
              }
            } else {
              console.log("Error data:", data);
              this.setCurrentUser(null);
              this.$router.push({ name: "Home" });
              throw new Error(data.message);
            }

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            // this.message = `<h3>Error, ${error}!</h3>>`;
            this.$router.push({ name: "SignIn" });
            this.setCurrentUser(null);
            this.loading = false;
          });
      } else {
        this.setCurrentUser(user);

        if (groupLevel === "isSuperAdmin") {
          if (event === "signIn") {
            this.$router.push({ name: "AdminDashboard" });
          }
        } else {
          this.$router.push({ name: "Home" });
        }
      }

      // try {
      //   // fetch and set user if they are an Advisor
      //   if (groupLevel === "isAdvisor") {
      //     const response = await API.graphql(graphqlOperation(getAdvisor, { id: id }));
      //     const advisor = response.data.getAdvisor;
      //     console.log("LLLLLL", response);
      //     if (advisor) {
      //       this.setCurrentUser(advisor);
      //       this.$router.push({ name: "UserDashboard" });
      //     } else {
      //       this.setCurrentUser(null);
      //       this.$router.push({ name: "Home" });
      //     }
      //   } else {
      //     if (groupLevel === "isSuperAdmin") {
      //       this.$router.push({ name: "AdminDashboard" });
      //     } else {
      //       this.$router.push({ name: "Home" });
      //     }
      //   }
      // } catch (error) {
      //   console.log("Error setting user in App:", error);
      //   this.$router.push({ name: "Home" });
      // }
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "@/scss/styles.scss";
</style>
