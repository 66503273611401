<!-- This layout wraps App.vue and is used to pass views into their appropriate Layout via route meta -->
<template>
  <component
    :is="layout"
    :navItems="navItems"
    :navShowSignInButton="navShowSignInButton"
  >
    <slot />
  </component>
</template>

<script>
const defaultLayout = "AppLayoutDefault";
export default {
  name: "BaseAppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
    navItems() {
      return this.$route.meta.navItems;
    },
    navShowSignInButton() {
      return this.$route.meta.navShowSignInButton;
    },
  },
};
</script>
