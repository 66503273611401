import VueRouter from "vue-router";
import { Auth } from "aws-amplify";

const routes = [
  {
    // RiskScore.us homepage - main public landing
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  {
    // RiskScore.us/survey
    // Where the user takes survey
    path: "/score/:id?",
    name: "PublicScoreSurvey",
    component: () => import("../views/PublicScoreSurvey.vue"),
    meta: {
      layout: "AppLayoutPublic",
      navItems: null,
      navShowSignInButton: false,
    },
  },
  {
    // RiskScore.us/your-score-report
    // Where the user gets their results report
    path: "/your-score-report/:id?",
    name: "PublicProspectScoreReport",
    props: true,
    component: () => import("../views/PublicProspectScoreReportV3.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/report/:id?",
    name: "PublicProspectScoreReportV3",
    props: true,
    component: () => import("../views/PublicProspectScoreReportV3.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/score-report/:id?",
    name: "PublicProspectScoreReportTest",
    props: true,
    component: () => import("../views/PublicProspectScoreReport.vue"),
    meta: { layout: "AppLayoutScoreReportV3", navItems: null },
  },
  {
    path: "/contact-requested/:id",
    name: "PublicProspectContactRequested",
    props: true,
    component: () => import("../views/PublicProspectContactRequested.vue"),
    meta: { layout: "AppLayoutPublic", navItems: null },
  },
  {
    path: "/reduce-risk/:id",
    name: "PublicProspectContactPostSurvey",
    props: true,
    component: () => import("../views/PublicProspectContactPostSurvey.vue"),
    meta: {
      layout: "AppLayoutPublic",
      navItems: null,
      navShowSignInButton: false,
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import("../views/SignIn.vue"),
    meta: {
      layout: "AppLayoutPublic",
      navItems: null,
      navShowSignInButton: false,
    },
    props: true,
  },
  {
    path: "/signup",
    name: "AdvisorSignUp",
    component: () => import("../views/AdvisorSignUp.vue"),
    meta: {
      layout: "AppLayoutPublic",
      navItems: null,
      navShowSignInButton: false,
    },
    // props: true,
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: () => import("../views/AdminDashboard.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },

  {
    path: "/lead-sheet/:id",
    name: "LeadSheet",
    component: () => import("../views/LeadSheet.vue"),
    meta: { authRequired: true, layout: "AppLayoutDefault" },
  },
  {
    path: "/admin/organizations",
    name: "AdminOrganizationManagement",
    component: () => import("../views/AdminOrganizationManagement.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/organization/edit/:id?",
    name: "AdminOrganizationEdit",
    component: () => import("../views/AdminOrganizationAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/organization/add/",
    name: "AdminOrganizationAdd",
    component: () => import("../views/AdminOrganizationAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/advisors",
    name: "AdminAdvisorManagement",
    component: () => import("../views/AdminAdvisorManagement.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/advisor/edit/:id?",
    name: "AdminAdvisorEdit",
    component: () => import("../views/AdminAdvisorAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/advisor/add/",
    name: "AdminAdvisorAdd",
    component: () => import("../views/AdminAdvisorAddEdit.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/prospects",
    name: "AdminProspectManagement",
    component: () => import("../views/AdminProspectManagement.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/reporting",
    name: "AdminReporting",
    component: () => import("../views/AdminReporting.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/import",
    name: "Import",
    component: () => import("../views/AdminImport.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/prospect-invite",
    name: "AdminProspectInvite",
    component: () => import("../views/AdminProspectInvite.vue"),
    meta: { authRequired: true, layout: "AppLayoutAdmin" },
  },
  {
    path: "/admin/resources",
    name: "AdminResources",
    component: () => import("../views/AdminResources.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/promos",
    name: "AdminPromoCodes",
    component: () => import("../views/AdminPromoCodes.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-editor",
    name: "AdminReportContentEditor",
    component: () => import("../views/AdminReportContentEditor.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-templates",
    name: "AdminReportTemplates",
    component: () => import("../views/AdminReportTemplates.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
  },
  {
    path: "/admin/report-template-builder/:templateId?",
    name: "AdminReportTemplateBuilder",
    component: () => import("../views/AdminReportTemplateBuilder.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutAdmin",
      allowedGroups: ["SuperAdmin"],
    },
    props: true,
  },

  // ORG/ADVISOR PAGES
  {
    path: "/account",
    name: "UserAccount",
    component: () => import("../views/UserAccount.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutSidebar",
      allowedGroups: ["Advisor"],
    },
  },
  {
    path: "/dashboard",
    name: "UserDashboard",
    component: () => import("../views/UserDashboard.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutSidebar",
      allowedGroups: ["Advisor"],
    },
  },
  {
    path: "/prospect/:prospectId",
    name: "UserProspectDetail",
    component: () => import("../views/UserProspectDetail.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutSidebar",
      allowedGroups: ["Advisor"],
    },
    props: true,
  },
  {
    path: "/run-score-report",
    name: "UserRunScoreReport",
    component: () => import("../views/UserRunScoreReport.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutSidebar",
      allowedGroups: ["Advisor"],
    },
  },
  {
    path: "/inactive-subscription",
    name: "PublicInactiveAccount",
    component: () => import("../views/PublicInactiveAccount.vue"),
    meta: {
      authRequired: false,
      layout: "AppLayoutPublic",
    },
  },
  {
    path: "/resources",
    name: "UserResources",
    component: () => import("../views/UserResources.vue"),
    meta: {
      authRequired: true,
      layout: "AppLayoutSidebar",
      allowedGroups: ["Advisor"],
    },
  },
  {
    path: "/security-statement",
    name: "SecurityStatement",
    component: () => import("../views/LegalSecurityStatement.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/LegalPrivacyPolicy.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/methodology",
    name: "Methodology",
    component: () => import("../views/Methodology.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () => import("../views/LegalTermsOfService.vue"),
    meta: { layout: "AppLayoutPublic" },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // base: process.env.BASE_URL,
  routes,
});

// if (process.env.VUE_APP_SCORE_ENV !== "production") {
//   router.addRoute({
//     path: "/report/:id?",
//     name: "PublicProspectScoreReportV3",
//     props: true,
//     component: () => import("../views/PublicProspectScoreReportV3.vue"),
//     meta: { layout: "AppLayoutScoreReportV3", navItems: null },
//   });
// }

router.beforeResolve(async (to, from, next) => {
  // console.log("to --> ", to);

  if (to.matched.some((route) => route.meta.authRequired)) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          // If user, they are either SuperAdmin or Advisor
          const isSuperAdmin =
            user.signInUserSession?.accessToken?.payload[
              "cognito:groups"
            ].includes("SuperAdmin");

          const isAdvisor =
            user.signInUserSession?.accessToken?.payload[
              "cognito:groups"
            ].includes("Advisor");

          // console.log(
          //   "User is a: ",
          //   user.signInUserSession?.accessToken?.payload["cognito:groups"],
          //   to.meta.allowedGroups
          // );

          // Path only for SuperAdmins
          if (to.meta.allowedGroups.includes("SuperAdmin")) {
            if (isSuperAdmin) {
              return next();
            } else if (isAdvisor) {
              // Authenticated, but not SuperAdmin - must be Advisor
              return next({ name: "UserDashboard" });
            } else {
              return next({ name: "Home" });
            }
          } else if (to.meta.allowedGroups.includes("Advisor")) {
            if (isAdvisor) {
              return next();
            } else {
              return next({ name: "Home" });
            }
          }
        }
      })
      .catch(() => {
        // AUTH REQUIRED. NO USER. SEND HOME
        next({
          name: "Home",
        });
      });
  } else {
    // Path without Auth required
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          const isSuperAdmin =
            user.signInUserSession?.accessToken?.payload[
              "cognito:groups"
            ].includes("SuperAdmin");

          // Authenticated, send to respective dashboard if lands on Auth/AdvisorAuth

          // if path is AdminAuth, check if they are a SA, if not they are advisors
          if (to.name === "SignIn" || to.name === "AdvisorSignUp") {
            if (isSuperAdmin) {
              // SuperAdmin
              return next({ name: "AdminDashboard" });
            } else {
              // Advisor
              return next({ name: "UserDashboard" });
            }
          } else {
            // Authenticated and Public Path
            return next();
          }
        } else {
          // Unauthenticated and Public Path
          return next();
        }
      })
      .catch((error) => {
        console.warn(error);
        next();
      });
  }
});

export default router;
