import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // isAuthenticated: false,
    currentUser: null,
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
  },
  mutations: {
    // SET_IS_AUTHENTICATED(state, payload) {
    //   state.isAuthenticated = payload;
    // },
    SET_CURRENT_USER(state, payload) {
      if (payload === null) {
        state.currentUser = null;
      } else {
        state.currentUser = Object.assign({}, payload);
      }
    },
  },
  actions: {
    // setIsAuthenticated({ commit }, payload) {
    //   return new Promise((resolve) => {
    //     commit("SET_IS_AUTHENTICATED", payload);
    //     resolve();
    //   });
    // },

    setCurrentUser({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_CURRENT_USER", payload);
        resolve();
      });
    },
  },

  // modules: {},
});
